.search__inner {
    position: relative;
    width: 100%;
    height: 80px;
    padding: 17px 40px;
    border-bottom: 1px solid var(--black100);

    label {
        width: 24px;
        height: 24px;
        background-image: url(../../img/icon/search.svg);
        position: absolute;
        left: 53px;
        top: 28px;
        background-size: cover;
    }

    input {
        background-color: var(--black100);
        border: 1px solid var(--black200);
        width: 100%;
        padding: 14px 45px;
        border-radius: 40px;
        font-size: 16px;
        color: var(--white);
    }
}

@media (max-width: 800px){
    .search__inner {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        z-index: 1000;
        background-color: var(--blackAlpha400);
        backdrop-filter: blur(10px);
        padding: 17px 20px 17px 200px;

        label {
            left: 213px;
        }
        input {
            padding: 14px 14px 14px 45px;
        }
    }
}