#channel {
    padding: 0;
}
.channel__header {
    width: 100%;
    height: 300px;
    background-color: #969696;
    padding-top: 100px;
    background-position: center center;
    position: relative;

    &::after {
        content: '';
        width: 100%;
        height: 100%;
        background-color: #00000064;
        position: absolute;
        left: 0; 
        top: 0;
    }
    .circle {
        width: 200px;
        height: 200px;
        border-radius: 50%;
        overflow: hidden;
        position: absolute;
        left: 50%;
        top: 150px;
        transform: translateX(-50%);
        z-index: 100;
    }
}

.channel__info {
    margin-top: 100px;
    color: #fff;
    text-align: center;

    .title {
        margin-bottom: 10px;
    }
    .desc {
        color: #adadad;
        padding: 0 10vw;
        margin-bottom: 30px;
        overflow-wrap: anywhere;
    }
    .info span {
        background-color: #a6a6a631;
        padding: 10px 20px;
        border-radius: 40px;
        margin: 4px;

        svg {
            width: 20px;
            height: 20px;
            vertical-align: -5px;
            margin-right: 5px;
        }
    }
}

.channel__video {
    padding: 40px;
    margin-top: 50px;
}
.channel__more {
    padding: 40px;

    button {
        width: 100%;
        border: 0;
        padding: 20px;
        background-color: #1a1a1a;
        color: #fff;
        transition: all 0.3s;
        margin-bottom: 20px;
        cursor: pointer;
        border-radius: 40px;

        &:hover {
            background-color: #111010;
        }
    }
}