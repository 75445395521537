#header {
    position: fixed;
    left: 0;
    top: 0;
    width: 260px;
    height: 100%;
    overflow-y: auto;
    z-index: 10000;
    border-right: 1px solid var(--black100);
}
#main {
    max-width: 2000px;
    min-height: 100vh;
    margin: 0 auto;
    padding-left: 260px;
}
#footer {
    text-align: center;
    background-color: var(--black100);
    border-top: 1px solid (var--black100);
    padding: 20px;
    margin-left: 260px;
    width: calc(100% - 260px);
    color: var(--black400);
    font-size: 0.8rem;
}

@media (max-width: 800px){
    #header {
        left: -260px;
        background-color: var(--black);
        transition: left 0.3s;

        h1 {
            position: fixed;
            left: 0;
            top: 0;
        }
        .header__menu {
            margin-top: 90px;
        };
        &.active {
            left: 0;
        }
    }
    #main {
        padding-left: 0;
    }
    #footer {
        width: 100%;
        margin-left: 0;
    }
}